import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from 'layouts/admin';
import UserLayout from 'layouts/user';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import OneSignal from 'react-onesignal';

function App() {
  // Place this at the top of your main entry file (e.g., index.js)
// const observerErrorHandler = () => {
//   const resizeObserverErr = window.console.error;
//   window.console.error = (...args) => {
//     if (args[0] && args[0].includes('ResizeObserver loop limit exceeded')) {
//       return;
//     }
//     resizeObserverErr.apply(window.console, args);
//   };
// };

// observerErrorHandler();

  const token = localStorage.getItem("token") || sessionStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate(); // Now assigned to a variable for use if needed

  const oneSignalInitialized = useRef(false); // Track initialization

  console.log(user);

  useEffect(() => {
    const initOneSignal = async () => {
      if (oneSignalInitialized.current) return; // Prevent re-initialization

      try {
        console.log('Initializing OneSignal...');
        await OneSignal.init({
          appId: '6c6657fa-1bab-40c9-8a86-373069cedc9e',
          allowLocalhostAsSecureOrigin: true,
          debug: true,
          kOSSettingsKeyAutoPrompt: true,
          promptOptions: {
            actionMessage: "We use cookies to improve your experience.",
            acceptButtonText: "Accept",
            cancelButtonText: "Cancel"
          },
          autoResubscribe: true, // Automatically resubscribe the user
          persistNotification: false,
        });

        console.log('OneSignal initialized');

        const isSubscribed = await OneSignal.User.PushSubscription.optedIn;
        if (!isSubscribed) {
          console.log('User is not subscribed, opting in...');
          await OneSignal.User.PushSubscription.optIn(); 
        } else {
          console.log('User is already subscribed.');
        }

        // Set tags for the user
        await OneSignal.User.addTag("user_type", "website_user");
        console.log('Tag set: user_type = website_user');

        oneSignalInitialized.current = true; // Mark as initialized

      } catch (error) {
        console.error('Error initializing OneSignal:', error);
      }
    };
    console.log('Environment:', process.env.REACT_APP_ENV);  // To check the environment variable

    initOneSignal();

    return () => {
      // Cleanup if necessary
    };

  }, []);

  const renderRoutes = () => {
    if (!token || !user?.role) {
      return <Route path="/*" element={<AuthLayout />} />;
    }

    switch (user.role) {
      case "CustomerService":
        return <Route path="/*" element={<AdminLayout />} />;
      case "user":
        return <Route path="/*" element={<AdminLayout />} />;
      case 'superAdmin':
        return <Route path="/*" element={<AdminLayout />} />;
      default:
        // You can redirect to a 404 page or show an error component here
        return <Route path="/*" element={<AuthLayout />} />;
    }
  };

  return (
    <>
      <ToastContainer />
      <Routes>
        {renderRoutes()}
      </Routes>
    </>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          <ThemeEditorProvider>
            <Router>
              <App />
            </Router>
          </ThemeEditorProvider>
        </React.StrictMode>
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);


// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
// import 'assets/css/App.css';
// import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
// import AuthLayout from './layouts/auth';
// import AdminLayout from 'layouts/admin';
// import UserLayout from 'layouts/user';
// import { ChakraProvider } from '@chakra-ui/react';
// import theme from 'theme/theme';
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Provider } from 'react-redux';
// import { store, persistor } from './redux/store';
// import { PersistGate } from 'redux-persist/integration/react';
// import OneSignal from 'react-onesignal';


// // // Register service worker for OneSignal
// // if ('serviceWorker' in navigator) {
// // 	window.addEventListener('load', () => {
// // 	  const swUrl = `${process.env.PUBLIC_URL}/OneSignalSDKWorker.js`;
// // 	  navigator.serviceWorker.register(swUrl).then(registration => {
// // 		console.log('Service Worker registered:', registration);
// // 	  }).catch(error => {
// // 		console.error('Error registering Service Worker:', error);
// // 	  });
// // 	});
// //   }


// function App() {
// 	const token = localStorage.getItem("token") || sessionStorage.getItem("token");

// 	const user = JSON.parse(localStorage.getItem("user"))
// 	useNavigate()
// 	console.log(user)

	 
// 	useEffect(() => {
// 		const initOneSignal = async () => {
// 		  try {
//         console.log('ggggggggggggggg');
// 			await OneSignal.init({
// 			  appId: '6c6657fa-1bab-40c9-8a86-373069cedc9e',
// 			  allowLocalhostAsSecureOrigin: true,
// 			  debug: true,
// 			  kOSSettingsKeyAutoPrompt: true,
// 			  promptOptions: {
// 				actionMessage: "We use cookies to improve your experience.",
// 				acceptButtonText: "Accept",
// 				cancelButtonText: "Cancel"
				
// 			},
// 			autoResubscribe: true, // Automatically resubscribe the user
// 			persistNotification: false,

// 			//   notifyButton: {
//             //     enable: true
//             // },
// 			//  serviceWorkerPath: `${process.env.PUBLIC_URL}/OneSignalSDKWorker.js`,
// 			});
// 			console.log('OneSignal initialized');

			
// 			const isSubscribed = await OneSignal.User.PushSubscription.optedIn;
// 			if (!isSubscribed) {
// 			  console.log('User is not subscribed, opting in...');
// 			  await OneSignal.User.PushSubscription.optIn(); 
// 			} else {
// 			  console.log('User is already subscribed.');
// 			 // console.log(OneSignal.User.PushSubscription.id);
// 			}
			
// 			 // Set tags for the user
// 			 await OneSignal.User.addTag("user_type", "website_user");
// 			 console.log('Tag set: user_type = website_user');

      
//       } catch (error) {
//         console.error('Error initializing OneSignal:', error);
//       }
// 		 };
	 
// 		initOneSignal();
	  
// 		return () => {
// 		  // OneSignal.removeEventListener('notificationOpened', handleNotificationOpened);
// 		  // OneSignal.removeEventListener('notificationDisplay', handleNotificationReceived);
// 		};

// 	  }, []);

// 	return (
// 		<>
// 			<ToastContainer />
// 			<Routes>
// 				{token && user?.role ? (
// 					user?.role === 'user' ?
// 						<Route path="/*" element={<UserLayout />} />
// 						: user?.role === 'superAdmin' ?
// 							<Route path="/*" element={<AdminLayout />} />
// 							: ''
// 				) : (
// 					<Route path="/*" element={<AuthLayout />} />
// 				)}
// 			</Routes>
// 		</>
// 	);
// }

// ReactDOM.render(
// 	<Provider store={store}>
// 		<PersistGate loading={null} persistor={persistor}>
// 			<ChakraProvider theme={theme}>
// 				<React.StrictMode>
// 					<ThemeEditorProvider>
// 						<Router>
// 							<App />
// 						</Router>
// 					</ThemeEditorProvider>
// 				</React.StrictMode>
// 			</ChakraProvider>
// 		</PersistGate>
// 	</Provider>
// 	, document.getElementById('root')
// );

