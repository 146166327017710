import { createSlice } from '@reduxjs/toolkit';
import { safeJsonParse } from '../utils/safeJsonParse';

const userJson = window.localStorage.getItem('user');
const user = safeJsonParse(userJson);

const initialState = {
    user: user || {}, // Default to an empty object if parsing fails
};

const localSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            // Update localStorage here
            window.localStorage.setItem('user', JSON.stringify(action.payload));
        },
        clearUser: (state) => {
            state.user = null;
            // Update localStorage here
            window.localStorage.removeItem('user');
        },
    },
});

export const { setUser, clearUser } = localSlice.actions;

export default localSlice.reducer;
