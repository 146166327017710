import { mode } from "@chakra-ui/theme-tools";
<style>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

</style>
export const globalStyles = {
  colors: {
    brand: {
      100: "#F5ECE3",
      200: "#E2C7B6",
      300: "#D1A492",
      400: "#C0806F",
      500: "#A5805B", // reference color
      600: "#8C694A",
      700: "#73523A",
      800: "#5A3B29",
      900: "#42251A",
    },
    brandScheme: {
      100: "#F5ECE3",
      200: "#E2C7B6",
      300: "#D1A492",
      400: "#C0806F",
      500: "#A5805B", // reference color
      600: "#8C694A",
      700: "#73523A",
      800: "#5A3B29",
      900: "#42251A",
    },
    brandTabs: {
      100: "#F5ECE3",
      200: "#E2C7B6",
      300: "#D1A492",
      400: "#C0806F",
      500: "#A5805B", // reference color
      600: "#8C694A",
      700: "#73523A",
      800: "#5A3B29",
      900: "#42251A",
    },
    secondaryGray: {
      100: "#F5F7FA",
      200: "#ECEFF4",
      300: "#E1E5EB",
      400: "#CED4DA",
      500: "#A0AEC0",
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "#1A202C",
    },
    red: {
      100: "#FFF5F5",
      300: "#FC8181",
      500: "#F56565",
      600: "#E53E3E",
    },
    blue: {
      50: "#EBF8FF",
      500: "#3182CE",
    },
    orange: {
      100: "#FFFAF0",
      400: "#F6AD55",
      500: "#ED8936",
    },
    green: {
      100: "#F0FFF4",
      500: "#48BB78",
    },
    navy: {
      50: "#E2E8F0",
      100: "#CBD5E0",
      200: "#A0AEC0",
      300: "#718096",
      400: "#4A5568",
      500: "#2D3748",
      600: "#1A202C",
      700: "#171923",
      800: "#1A202C",
      900: "#0D0D0D",
    },
    gray: {
      100: "#F7FAFC",
      200: "#EDF2F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
      500: "#A0AEC0",
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "#1A202C",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: "#E1E5EB !important",
        // bg: mode("secondaryGray.300", "navy.900")(props),
        fontFamily: "Cairo, sans-serif",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "Cairo, sans-serif",
      },
    }),
  },
};
