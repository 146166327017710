export const constant = (() => {
    console.log('Environment:', process.env.REACT_APP_ENV);  // Check the environment variable

    switch (process.env.REACT_APP_ENV) {
        case 'prod':
            return {
                baseUrl: "https://aldawlia-crm.armaarmy.com/",
                // Add other production constants here
            };
        case 'test':
            return {
                baseUrl: "https://aldawlia-crm.armaarmy.com/",
                // Add other test constants here
            };
        case 'local':
        default:
            return {
                baseUrl: "http://localhost:5001/",
                // Add other development constants here
            };
    }
})();
