import { Icon } from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import { FaTicketAlt } from "react-icons/fa"; // Import the ticket icon
import { MdOutlineSupportAgent } from "react-icons/md";
import { IoHomeOutline,IoPeopleOutline,IoSettingsOutline,IoQrCodeOutline } from "react-icons/io5";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { FaBuildingUser } from "react-icons/fa6";

import {
  MdContacts,
  MdHome,
  MdInsertChartOutlined,
  // MdLeaderboard,
  MdLock,
  MdSettings
} from "react-icons/md";
// icon
import React from "react";
// import { AiFillFolderOpen, AiOutlineMail } from "react-icons/ai";
import { FaCalendarAlt, FaRupeeSign, FaTasks, FaWpforms, FaNewspaper, FaHouseUser } from "react-icons/fa";
import { LuBuilding2,LuBuilding } from "react-icons/lu";
// import { PiPhoneCallBold } from "react-icons/pi";
import { FaCreativeCommonsBy,FaMobileAlt } from "react-icons/fa";
// import { SiGooglemeet } from "react-icons/si";
import { ROLE_PATH } from "./roles";
import ChangeImage from "views/admin/image";
import Validation from "views/admin/validation";
import CustomField from "views/admin/customField";
import TableField from "views/admin/tableField";
import { TbExchange, TbTableColumn } from "react-icons/tb";
import { GrValidate } from "react-icons/gr";
import { VscFileSubmodule } from "react-icons/vsc";

// Admin Imports
const MainDashboard = React.lazy(() => import("views/admin/default"));

// My component
// const Contact = React.lazy(() => import('views/admin/contact'));
// const ContactView = React.lazy(() => import('views/admin/contact/View'));
// const ContactImport = React.lazy(() => import("views/admin/contact/components/ContactImport"));

const User = React.lazy(() => import("views/admin/users"));
const UserView = React.lazy(() => import("views/admin/users/View"));

// const Property = React.lazy(() => import("views/admin/property"));
// const PropertyView = React.lazy(() => import("views/admin/property/View"));
// const PropertyImport = React.lazy(() => import("views/admin/property/components/PropertyImport"))

// const Lead = React.lazy(() => import("views/admin/lead"));
// const LeadView = React.lazy(() => import("views/admin/lead/View"));
// const LeadImport = React.lazy(() => import("views/admin/lead/components/LeadImport"));

// const Communication = React.lazy(() => import("views/admin/communication"));

// const Task = React.lazy(() => import("views/admin/task"));
// const TaskView = React.lazy(() => import("views/admin/task/components/taskView"));
// const Calender = React.lazy(() => import("views/admin/calender"));
// const Payments = React.lazy(() => import("views/admin/payments"));
const Role = React.lazy(() => import("views/admin/role"));

// const Document = React.lazy(() => import("views/admin/document"));

// const EmailHistory = React.lazy(() => import("views/admin/emailHistory"));
// const EmailHistoryView = React.lazy(() => import("views/admin/emailHistory/View"));

// const Meeting = React.lazy(() => import("views/admin/meeting"));
// const MettingView = React.lazy(() => import("views/admin/meeting/View"));

// const PhoneCall = React.lazy(() => import("views/admin/phoneCall"));
// const PhoneCallView = React.lazy(() => import("views/admin/phoneCall/View"));

const Report = React.lazy(() => import("views/admin/reports"));

// Auth Imports
const SignInCentered = React.lazy(() => import("views/auth/signIn"));
// admin setting 
const AdminSetting = React.lazy(() => import("views/admin/adminSetting"));
// const validation = React.lazy(() => import("views/admin/validation"));
const module = React.lazy(() => import("views/admin/moduleName"));

// Import your ticket components
const Tickets = React.lazy(() => import("views/admin/ticket"));
const TicketView = React.lazy(() => import("views/admin/ticket/components/eventView"));
const AddTicket = React.lazy(() => import("views/admin/ticket/components/AddEdit"));
const EditTicket = React.lazy(() => import("views/admin/ticket/components/edit"));
// Import Settings Module
const Settings = React.lazy(() => import("views/admin/settings/index"));
// Import Projects Module
const Projects = React.lazy(() => import("views/admin/projects/index"))
const ProjectView = React.lazy(() => import("views/admin/projects/View"))
const phaseView = React.lazy(() => import("views/admin/projects/Components/PhaseView"))
// Import News Module
const app = React.lazy(() => import("views/admin/app/index"))
// Import Customer Module
const Customer = React.lazy(() => import("views/admin/customer/index"))
const discover = React.lazy(() => import("views/admin/discover/index"))
const qrCode = React.lazy(() => import("views/admin/qr/index.js"))

const routes = [
  // ========================== Dashboard ==========================
  {
    name: "Dashboard",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/default",
    icon: <Icon as={IoHomeOutline} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  //========================= Settings =============================
  {
    name: "Settings",
    layout:[ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/settings",
    icon: <Icon as={IoSettingsOutline} width='20px' height='20px' color='inherit' />,
    component: Settings,
  },
  //========================= Project ==============================
  {
    name: "Projects",
    layout:[ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/projects",
    icon: <Icon as={HiOutlineBuildingOffice2} width='20px' height='20px' color='inherit' />,
    component: Projects,
  },{
    name: "Projects",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Projects",
    under: "projects",
    path: "/projectView/:id",
    component: ProjectView,
  },
  {
    name: "Projects",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Projects",
    under: "Projects",
    path: "/:projectId/phase/:phaseId",
    component: phaseView,
  },
  // ========================== Admin Layout ==========================
    // ------------- Property Routes ------------------------
    // {
    //   name: "Properties",
    //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    //   path: "/properties",
    //   icon: <Icon as={HiOutlineBuildingOffice} width='20px' height='20px' color='inherit' />,
    //   component: Property,
    // },
    // {
    //   name: "Properties ",
    //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    //   parentName: "Properties",
    //   under: "properties",
    //   path: "/propertyView/:id",
    //   component: PropertyView,
    // },
    // {
    //   name: "Property Import",
    //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    //   both: true,
    //   under: "properties",
    //   parentName: "Properties",
    //   path: "/propertyImport",
    //   component: PropertyImport,
    // },
  // ------------- lead Routes ------------------------
  // {
  //   name: "Leads",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/lead",
  //   icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
  //   component: Lead,
  // },
  // {
  //   name: "Leads",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "lead",
  //   parentName: "Leads",
  //   path: "/leadView/:id",
  //   component: LeadView,
  // },
  // {
  //   name: "Lead Import",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "lead",
  //   parentName: "Leads",
  //   path: "/leadImport",
  //   component: LeadImport,
  // },
  // --------------- contact Routes --------------------
  // {
  //   name: "Contacts",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/contacts",
  //   icon: <Icon as={MdContacts} width='20px' height='20px' color='inherit' />,
  //   component: Contact,
  // },
  // {
  //   name: "Contacts",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "contacts",
  //   parentName: "Contacts",
  //   path: "/contactView/:id",
  //   component: ContactView,
  // },
  // {
  //   name: "Contact Import",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   both: true,
  //   under: "contacts",
  //   parentName: "Contacts",
  //   path: "/contactImport",
  //   component: ContactImport,
  // },
  //------------------------------News---------------------------------------
  {
    name: "App Management",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/app-management",
    icon: <Icon as={FaMobileAlt} width='20px' height='20px' color='inherit' />,
    component: app,
  },
  {
    name: "Discover",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/discover-places",
    icon: <Icon as={IoPeopleOutline} width='20px' height='20px' color='inherit' />,
    component: discover,
  },
  {
    name: "Visitors Pass",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/qrCodes",
    icon: <Icon as={IoQrCodeOutline} width='20px' height='20px' color='inherit' />,
    component: qrCode,
  },
  // -----------------------------Admin setting-------------------------------------
  {
    name: "Admin Setting",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "admin",
    under: "admin",
    path: "/admin-setting",
    component: AdminSetting,
  },

  // // ------------- Communication Integration Routes ------------------------
  // {
  //   name: "Communication Integration",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],

  //   path: "/communication-integration",
  //   icon: <Icon as={GiSatelliteCommunication} width='20px' height='20px' color='inherit' />,
  //   component: Communication,
  // },
  // ------------- Task Routes ------------------------
  // {
  //   name: "Tasks",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/task",
  //   icon: <Icon as={FaTasks} width='20px' height='20px' color='inherit' />,
  //   component: Task,
  // },
  // {
  //   name: "Tasks",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "task",
  //   parentName: "Tasks",
  //   path: "/view/:id",
  //   component: TaskView,
  // },

  // ------------- Ticket Routes ------------------------
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/ticket",
    icon: <Icon as={MdOutlineSupportAgent} width='20px' height='20px' color='inherit' />,
    component: Tickets,
  },
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Ticket",
    parentName: "Ticket",
    path: "/ticket/notesview/:id",
    component: TicketView,
  },
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Ticket",
    parentName: "Ticket",
    path: "/ticket/add",
    component: AddTicket,
  },
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Ticket",
    parentName: "Ticket",
    path: "/ticket/edit/:id",
    component: EditTicket,
  },
  // ------------- Meeting Routes ------------------------
  // {
  //   name: "Meetings",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/metting",
  //   icon: <Icon as={SiGooglemeet} width='20px' height='20px' color='inherit' />,
  //   component: Meeting,
  // },
  // {
  //   name: "Meetings ",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "Meetings",
  //   parentName: "Meetings",
  //   path: "/metting/:id",
  //   component: MettingView,
  // },
  // ------------- Phone Routes ------------------------
  // {
  //   name: "Calls",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/phone-call",
  //   icon: <Icon as={PiPhoneCallBold} width='20px' height='20px' color='inherit' />,
  //   component: PhoneCall,
  // },
  // {
  //   name: "Calls",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "phone-call",
  //   parentName: "Calls",
  //   path: "/phone-call/:id",
  //   component: PhoneCallView,
  // },
  // ------------- Email Routes------------------------
  // {
  //   // separator: 'History',
  //   name: "Emails",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/email",
  //   icon: <Icon as={AiOutlineMail} width='20px' height='20px' color='inherit' />,
  //   component: EmailHistory,
  // },
  // {
  //   name: "Emails ",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "Emails",
  //   parentName: "Emails",
  //   path: "/Email/:id",
  //   component: EmailHistoryView,
  // },
  // ------------- Calender Routes ------------------------
  // {
  //   name: "Calender",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/calender",
  //   icon: <Icon as={FaCalendarAlt} width='20px' height='20px' color='inherit' />,
  //   component: Calender,
  // },
  // ------------- Payments Routes ------------------------
  // {
  //   name: "Payments",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/payments",
  //   icon: <Icon as={FaRupeeSign} width='20px' height='20px' color='inherit' />,
  //   component: Payments,
  // },
  //-----------------------------Customer Route--------------------------
  {
    name: "Customer",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/Customers",
    icon: <Icon as={FaBuildingUser} width='20px' height='20px' color='inherit' />,
    component: Customer,
  },
  // ------------- Roles Routes ------------------------
  {
    name: "Roles",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/role",
    under: "role",
    icon: <Icon as={FaCreativeCommonsBy} width='20px' height='20px' color='inherit' />,
    component: Role,
  },
  {
    name: "Custom Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/custom-Fields",
    under: "customField",
    icon: <Icon as={FaWpforms} width='20px' height='20px' color='inherit' />,
    component: CustomField,
  },
  {
    name: "Change Images",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/change-images",
    under: "image",
    icon: <Icon as={TbExchange} width='20px' height='20px' color='inherit' />,
    component: ChangeImage,
  },
  {
    name: "Validation",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/validations",
    under: "Validation",
    icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
    component: Validation,
  },
  {
    name: "Table Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/table-field",
    under: "tableField",
    icon: <Icon as={TbTableColumn} width='20px' height='20px' color='inherit' />,
    component: TableField,
  },
  // {
  //   name: "Module",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/module",
  //   under: "module",
  //   icon: <Icon as={VscFileSubmodule} width='20px' height='20px' color='inherit' />,
  //   component: module,
  // },
  // // ------------- Text message Routes ------------------------
  // {
  //   name: "Text Msg",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],
  //
  //   path: "/text-msg",
  //   icon: <Icon as={MdOutlineMessage} width='20px' height='20px' color='inherit' />,
  //   component: TextMsg,
  // },
  // {
  //   name: "Text Msg View",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],
  //
  //   under: "text-msg",
  //   path:  text-msg/:id",
  //   component: TextMsgView,
  // },
  // ------------- Document Routes ------------------------
  // {
  //   name: "Documents",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/documents",
  //   icon: <Icon as={AiFillFolderOpen} width='20px' height='20px' color='inherit' />,
  //   component: Document,
  // },
  // ----------------- Reporting Layout -----------------
  {
    name: "Reporting and Analytics",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/reporting-analytics",
    icon: <Icon as={MdInsertChartOutlined} width='20px' height='20px' color='inherit' />,
    component: Report,
  },
  // ------------- user Routes ------------------------
  {
    name: "Users",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/user",
    under: "user",
    icon: <Icon as={HiUsers} width='20px' height='20px' color='inherit' />,
    component: User,
  },
  {
    name: "User View",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Email",
    under: "user",
    path: "/userView/:id",
    component: UserView,
  },
  // ========================== User layout ==========================

  // ========================== auth layout ==========================
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
];

export default routes;
