import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiBell } from 'react-icons/fi';
import axios from 'axios';
import { constant } from '../../constant';
import { Tooltip } from 'antd';

const BASE_URL = `${constant.baseUrl}api`;

const api = axios.create({
  baseURL: BASE_URL,
});

const head = {
  headers: {
    Authorization: localStorage.getItem('token') || sessionStorage.getItem('token'),
  },
};

const NotificationsContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const BellIcon = styled(FiBell)`
  font-size: 20px;  /* Larger size for modern look */
  color: #000;  /* Modern blue color */
`;

const Badge = styled.span`
  height: 25px;
  width: 20px;
  position: absolute;
  left: 19px;
  top: -5px;
  right: -8px;
  background-color: #ff4d4f;
  color: white;
  border-radius: 50%;
  padding: 1px 1px;
  font-size: 12px;
  font-weight: bold;
  display: inline;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.count > 0 ? 'inline' : 'none')};
`;

const Dropdown = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 320px;
  max-height: 400px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
  z-index: 1000;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  animation: fadeIn 0.3s ease;  /* Smooth fade-in animation */
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const NotificationItem = styled.div`
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;  /* Smooth transition on hover */

  &:hover {
    background-color: #f0f0f0;  /* Subtle hover effect */
  }
`;

const NotificationText = styled.div`
  font-size: 14px;
  color: #333;
  margin-right: 10px;
  flex: 1;  /* Allow text to take up available space */
`;

const ReadButton = styled.button`
  background-color: #A5805B;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 12px;  /* Adjusted padding for better touch target */
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;  /* Smooth transition on hover */

  &:hover {
    background-color: #B5804B;  /* Darker shade on hover */
  }
`;

const NoNotifications = styled.div`
  padding: 20px;
  text-align: center;
  color: #999;
`;

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/notifications/notifications', head);
      setNotifications(response.data.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleDropdownToggle = () => {
    fetchNotifications();
    setIsOpen(!isOpen);
  };

  const markAsRead = async (id) => {
    try {
      await api.put(`/notifications/mark-as-seen/${id}`, head);
      setNotifications(notifications.filter((notif) => notif.notification_id !== id));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <NotificationsContainer onClick={handleDropdownToggle}>
      <Tooltip title="Notifications">
        <BellIcon />
        <Badge count={notifications.length}>{notifications.length}</Badge>
      </Tooltip>
      <Dropdown isOpen={isOpen}>
        {notifications.length > 0 ? (
          notifications.map((notif) => (
            <NotificationItem key={notif.notification_id}>
              <NotificationText>{notif.contents}</NotificationText>
              <ReadButton onClick={() => markAsRead(notif.notification_id)}>Read</ReadButton>
            </NotificationItem>
          ))
        ) : (
          <NoNotifications>No new notifications</NoNotifications>
        )}
      </Dropdown>
    </NotificationsContainer>
  );
}

export default Notifications;
