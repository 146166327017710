import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";

// Define custom color palette
const colors = {
  primary: {
    50: '#fffaf0',
    100: '#fef2c4',
    200: '#fde98f',
    300: '#fce056',
    400: '#fbd71d',
    500: '#a5805b', // Reference color
    600: '#b4862b',
    700: '#8d671e',
    800: '#664816',
    900: '#3e2b0d',
  },
  secondary: {
    50: '#fffaf0',
    100: '#fef2c4',
    200: '#fde98f',
    300: '#fce056',
    400: '#fbd71d',
    500: '#a5805b', // Reference color
    600: '#8d671e',
    700: '#664816',
    800: '#3e2b0d',
    900: '#1e1e1e',
  },
  brand: {
    100: "#F5ECE3",
    200: "#E2C7B6",
    300: "#D1A492",
    400: "#C0806F",
    500: "#A5805B", // reference color
    600: "#8C694A",
    700: "#73523A",
    800: "#5A3B29",
    900: "#42251A",
  },
  brandScheme: {
    100: "#F5ECE3",
    200: "#E2C7B6",
    300: "#D1A492",
    400: "#C0806F",
    500: "#A5805B", // reference color
    600: "#8C694A",
    700: "#73523A",
    800: "#5A3B29",
    900: "#42251A",
  },
  brandTabs: {
    100: "#F5ECE3",
    200: "#E2C7B6",
    300: "#D1A492",
    400: "#C0806F",
    500: "#A5805B", // reference color
    600: "#8C694A",
    700: "#73523A",
    800: "#5A3B29",
    900: "#42251A",
  },
  secondaryGray: {
    100: "#F5F7FA",
    200: "#ECEFF4",
    300: "#E1E5EB",
    400: "#CED4DA",
    500: "#A0AEC0",
    600: "#718096",
    700: "#4A5568",
    800: "#2D3748",
    900: "#1A202C",
  },
  red: {
    100: "#FFF5F5",
    300: "#FC8181",
    500: "#F56565",
    600: "#E53E3E",
  },
  blue: {
    50: "#EBF8FF",
    500: "#3182CE",
  },
  orange: {
    100: "#FFFAF0",
    400: "#F6AD55",
    500: "#ED8936",
  },
  green: {
    100: "#F0FFF4",
    500: "#48BB78",
  },
  navy: {
    50: "#E2E8F0",
    100: "#CBD5E0",
    200: "#A0AEC0",
    300: "#718096",
    400: "#4A5568",
    500: "#2D3748",
    600: "#1A202C",
    700: "#171923",
    800: "#1A202C",
    900: "#0D0D0D",
  },
  gray: {
    100: "#F7FAFC",
    200: "#EDF2F7",
    300: "#E2E8F0",
    400: "#CBD5E0",
    500: "#A0AEC0",
    600: "#718096",
    700: "#4A5568",
    800: "#2D3748",
    900: "#1A202C",
  },
};

export default extendTheme(
  { colors }, // Add custom colors
  { breakpoints }, // Breakpoints
  globalStyles,
  badgeStyles, // badge styles
  buttonStyles, // button styles
  linkStyles, // link styles
  progressStyles, // progress styles  
  sliderStyles, // slider styles
  inputStyles, // input styles
  textareaStyles, // textarea styles
  switchStyles, // switch styles
  CardComponent // card component
);
